/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import ProjectCard from "../components/project-card"
import SEO from "../components/seo"
import projectData from "../util/project.json"

export const projectListQuery = graphql`
  query projectListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "project-page" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            align
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 848, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
                sizes {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
const Pagination = props => (
  <div sx={projectStyles.listPagination}>
    <ul>
      {!props.isFirst && (
        <li>
          <Link to={props.prevPage} rel="prev">
            <span class="icon">&rarr;</span> Previous
          </Link>
        </li>
      )}
      {Array.from({ length: props.numbersPages }, (_, i) => (
        <li key={`pagination-number${i + 1}`}>
          <Link
            to={`${props.projectSlug}${i === 0 ? "" : i + 1}`}
            className={props.currentPage === i + 1 ? "is-active num" : "num"}
          >
            {i + 1}
          </Link>
        </li>
      ))}
      {!props.isLast && (
        <li>
          <Link to={props.nextPage} rel="next">
            Next <span class="icon">&rarr;</span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

class ProjectIndex extends React.Component {
  render() {
    const { data } = this.props
    const { currentPage, numbersPages } = this.props.pageContext
    const projectSlug = "/project/"
    const isFirst = currentPage === 1
    const isLast = currentPage === numbersPages
    const prevPage =
      currentPage - 1 === 1
        ? projectSlug
        : projectSlug + (currentPage - 1).toString()
    const nextPage = projectSlug + (currentPage + 1).toString()

    const Project = data.allMarkdownRemark.edges
      .filter(edge => !!edge.node.frontmatter.date)
      .map(edge => <ProjectCard key={edge.node.id} data={edge.node} />)
    let props = {
      isFirst,
      prevPage,
      numbersPages,
      projectSlug,
      currentPage,
      isLast,
      nextPage,
    }

    return (
      <Layout sx={projectStyles.projectContainer}>
        <SEO
          title={"Project — Page " + currentPage + " of " + numbersPages}
          description={"Project page " + currentPage + " of " + numbersPages}
        />
        <div sx={projectStyles.projectListContainer}>
          <div sx={projectStyles.pageHead}>
            <h1>{projectData.title}</h1>
            <p>{projectData.description}</p>
          </div>
          <div>{Project}</div>
        </div>
        {numbersPages > 1 && <Pagination {...props} />}
      </Layout>
    )
  }
}

export default ProjectIndex

const projectStyles = {
  projectContainer: {
    variant: "variants.section",
    minHeight: "70vh",
  },
  projectListContainer: {
    variant: "variants.container",
  },
  pageHead: {
    variant: "variants.pageHead",
  },
  listPagination: {
    variant: "variants.pageListPagination",
  },
}
